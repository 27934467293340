import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import HubspotForm from "react-hubspot-form"

export default function DownloadAIReport() {
  return (
    <Layout
      title="Download the report on The Future of Customer Support"
      keywords="download ai report"
      description="Download the report on The Future of Customer Support. The report explores the transformative impact of AI on customer support by 2025, with 95% of interactions driven by AI. "
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <script
          charSet="utf-8"
          src="https://js.hsforms.net/forms/shell.js"
        ></script>
        <link
          rel="canonical"
          href="https://irisagent.com/ai-report-2025-download/"
        />
      </Helmet>
      <section className="inner-page" style={{ margin: "2% 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <h1>Report: The Future of Customer Support and Trends in 2025</h1>
                <p><br/>
                   The report explores the transformative impact of AI on customer support by 2025, with 95% of interactions driven by AI.

 <br/><br/> It highlights trends like automation, self-service, hyper-personalization, emotional intelligence, and omnichannel integration, showcasing improved efficiency and ROI.
                </p><br/>
                <img
                  src="/img/download-ai-report.png"
                  className="img-fluid"
                  alt="GPT Whitepaper"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div>
                <br />
                <br />
                <br />
                <br />
                <br />
                {/* <HubspotForm
                  portalId='7494256'
                  formId='29f4e374-b150-48db-9a15-668eeac19625'
                  loading={<div>Loading...</div>}
                /> */}
                <HubspotForm
                  portalId='7494256'
                  formId='467360a0-1531-4bc4-a384-9c28ac4bbc84'
                  loading={<div>Loading...</div>}
                />

              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
